<template>
  <div class="page-sub-box">
    <div class="fl per100">
      <table class="table_form line-bin">
        <thead class="sub_title_txt">
          <tr>
            <td colspan="4" style="padding-top: 0">
              <h2>상위 작업</h2>
            </td>
          </tr>
        </thead>
        <colgroup>
          <col style="width: 10%" />
          <col style="width: 40%" />
          <col style="width: 10%" />
          <col style="width: 40%" />
        </colgroup>

        <tbody>
          <tr>
            <th scope="row" class="alT">
              <label>작업명</label>
            </th>
            <td>
              <DxTextBox v-model="task.taskNm" type="text" width="100%" />
            </td>
            <th scope="row" class="alT">
              <label>설명</label>
            </th>
            <td>
              <DxTextBox v-model="task.description" type="text" width="100%" />
            </td>
          </tr>

          <tr>
            <th scope="row" class="alT">
              <label>순서</label>
            </th>
            <td>
              <DxNumberBox v-model="task.taskOrd" :styling-mode="stylingMode" width="100%" />
            </td>
            <th scope="row" class="alT">
              <label>Interval</label>
            </th>
            <td>
              <DxNumberBox v-model="task.interval" :styling-mode="stylingMode" width="100%" />
            </td>
          </tr>
          <tr>
            <th scope="row" class="alT">
              <label>파일명</label>
            </th>
            <td>
              <DxTextBox type="text" width="100%" :value="task.queryFile" :disabled="true" />
            </td>
            <th scope="row" class="alT">
              <label>파일 변경</label>
            </th>
            <td>
              <DxFileUploader
                select-button-text="파일 업로드"
                width="100%"
                label-text=""
                :accept="files.accept"
                :multiple="files.multiple"
                :upload-mode="files.uploadMode"
                :upload-file="files.onUploadClick"
                @value-changed="e => (file = e.value)"
                :max-file-size="files.maxFileSize"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="fl per100">
      <table class="table_form line-bin">
        <colgroup>
          <col style="width: auto" />
        </colgroup>
        <thead class="sub_title_txt">
          <tr>
            <td>
              <h2>하위 작업</h2>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <esp-dx-data-grid ref="subTaskGridRef" :data-grid="subTaskGrid" @init-new-row="onInitNewRow" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import DxTextBox from 'devextreme-vue/text-box';
  import { DxNumberBox } from 'devextreme-vue/number-box';
  import { DxSwitch } from 'devextreme-vue/switch';
  import { isSuccess } from '@/plugins/common-lib';
  import { DxFileUploader } from 'devextreme-vue/file-uploader';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    props: {
      modalData: {
        type: Object,
      },
    },
    components: {
      EspDxDataGrid,
      DxFileUploader,
      DxTextBox,
      DxNumberBox,
    },
    data() {
      return {
        task: {},
        stylingMode: 'outlined', //outlined, underlined, filled
        file: null,
        files: {
          isShowFileBox: true,
          accept: 'application/xml',
          multiple: false,
          uploadMode: 'useButtons',
          allowedFileExtensions: ['.xml'],
          readyToUploadMessage: '파일 업로드 준비중',
        },
        subTaskGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'subTaskGridRef',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          apiActionNm: {
            delete: 'EUC_SUB_TASK_DELETE',
            loading: true,
          },
          customEvent: {},
          showActionButtons: {
            delete: true,
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'cell', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          disableTotalCount: true,
          columns: [
            {
              caption: '처리방법',
              dataField: 'approach',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '소스',
              dataField: 'sourceFd',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
            },
            {
              caption: '타겟',
              dataField: 'targetFd',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
            },
            {
              caption: '설명',
              dataField: 'description',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '순서',
              dataField: 'subOrd',
              width: 90,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '사용여부',
              dataField: 'viewFl',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                const switchBtn = new DxSwitch({
                  propsData: {
                    value: options.value === 'Y',
                    onValueChanged: () => {
                      this.onChangedViewCd(options.data);
                    },
                  },
                });
                switchBtn.$mount();
                container.append(switchBtn.$el);
              },
            },
            {
              caption: '수정자',
              dataField: 'editId',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '수정일',
              dataField: 'editDt',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateCellValue: this.formatDt,
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      async onChangedViewCd(data) {
        const viewFl = data.viewFl === 'Y' ? 'N' : 'Y';
        if (data.id) {
          const payload = {
            actionname: 'EUC_SUB_TASK_UPDATE',
            data: [
              {
                id: data.id,
                viewFl,
              },
            ],
            loading: false,
          };

          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_msgContents('CMN_SUC_UPDATE'));
            this.$refs.subTaskGridRef.getGridInstance.refresh();
          } else {
            this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          }
        } else {
          data.viewFl = viewFl;
        }
      },
      /** @description : 데이터 추가 시 기본 값 적용하기 위함 */
      onInitNewRow(e) {
        e.data.taskId = this.task.id;
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.editDt) {
          return this.$_commonlib.formatDate(rowData.editDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      async selectSubTasks() {
        this.subTaskGrid.dataSource = new CustomStore({
          key: 'id',
          load: async () => {
            const params = {
              taskId: this.task.id,
              viewFl: 'Y',
            };
            const payload = {
              actionname: 'EUC_SUB_TASK_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
    },
    created() {
      this.task = this.modalData.data;
    },
    mounted() {
      this.selectSubTasks();
    },
  };
</script>
